import React, { useEffect, useState } from "react";
import {
  Chip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { MdOutlineLocationOn } from "react-icons/md";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

export default function GesCardPassive5() {
  return (
    <Button
      // onClick={() => navigate("/detail")}
      className="ml-6 mt-6 monitor:w-96 desktop:w-[22rem] blaptop:w-80 laptop:w-[22rem] slaptop:w-80 btablet:w-80 tablet:w-96 stablet:w-96 h-max bg-blue-950 text-white rounded-lg cursor-not-allowed"
    >
      <div className="absolute z-50 ml-8 mt-28 rounded-lg select-none items-center bg-neutral-400 py-3 px-12 font-sans text-xs font-bold uppercase text-gray-100">
        <span className="text-lg">kurulum aşamasında</span>
      </div>

      <Card className="monitor:w-96 desktop:w-[22rem] blaptop:w-80 laptop:w-[22rem] slaptop:w-80 btablet:w-80 tablet:w-96 stablet:w-96 h-max bg-blue-950 opacity-40 text-white capitalize rounded-lg">
        <CardBody className="flex ">
          <Typography variant="h5" color="blue-gray" className="mb-2 m-1">
            <p className="ml-1 font-semibold ">Pınar Mermer</p>
            <p className="flex">
              <MdOutlineLocationOn />
              <p className="text-xs">Afyonkarahisar</p>
            </p>
          </Typography>
          <Typography className="flex m-1">
            <p className="absolute right-2">GES</p>
            <p className="text-xs absolute right-2 top-7 ">
              Kurulu Güç: 1,92 MW
            </p>
          </Typography>
        </CardBody>

        <div className="flex flex-row">
          <Card className="mt-2 mx-2 h-20 text-white bg-gradient-to-b from-lime-500 to-lime-800 rounded-lg basis-1/2 text-center">
            <p className="text-sm font-semibold mt-2">Günlük Enerji</p>
            <p className="text-3xl font-semibold">6,4 MWh</p>
          </Card>
          <Card className="mr-2 mt-2 h-20 text-white bg-gradient-to-b from-lime-500 to-lime-800 rounded-lg basis-1/2 text-center">
            <p className="text-sm font-semibold mt-2">Toplam Enerji</p>
            <p className="text-3xl font-semibold">1,1 MW</p>
          </Card>
        </div>

        <div className="flex flex-row">
          <Card className="mt-2 mx-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">Anlık Güç</p>
            <p className="text-lg font-semibold">198 kW</p>
          </Card>
          <Card className="mr-2 mt-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">Giriş Gücü</p>
            <p className="text-xl font-semibold">250 kW</p>
          </Card>
          <Card className="mr-2 mt-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">İnvertör Miktarı</p>
            <p className="text-xl font-semibold">6</p>
          </Card>
        </div>
        <div className="flex flex-row">
          <Card className="my-2 mx-2 h-16 text-white bg-gradient-to-b from-sky-800 to-sky-950 rounded-lg basis-1/2 pl-1 flex flex-row">
            <p className="text-lg font-semibold mt-4 basis-1/2">Verimlilik</p>
            <p className="text-lg font-semibold mt-4 basis-1/2">% 99</p>
          </Card>
          <Card className="mr-2 my-2 h-16 text-white bg-gradient-to-b from-sky-800 to-sky-950 rounded-lg basis-1/2 pl-1 flex flex-row">
            <p className="text-lg font-semibold mt-1 basis-1/2">
              Santral <br />
              Durumu
            </p>
            <div className="basis-1/2 pl-7">
              <Chip
                value="Pasif"
                className=" rounded-full text-center text-sm mt-4 mr-2 capitalize bg-amber-600"
              />
            </div>
          </Card>
        </div>
      </Card>
    </Button>
  );
}

//
