import React, { createContext, useContext, useState } from "react";
import ApiService from "../services/api";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [invDatas, setInvDatas] = useState([]); // State ismi düzeltildi
  const [error, setError] = useState(null);

  const getInvDatas = async () => {
    try {
      const data = await ApiService.getInvDatas(); 
      setInvDatas(data);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        getInvDatas,
        invDatas,
        error,
        setError,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
//global context
//
