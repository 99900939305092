import axios from "axios";

const BASE_URL = "https://solar.akgunteknoloji.dev/api/";

class ApiService {
  static getInvDatas = async () => {
    try {
      // console.log("Making API call to:", `${BASE_URL}invdatas/get-invdata`);
      const response = await axios.get(`${BASE_URL}invdatas/get-invdata`);
      // console.log("API response:", response);

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      throw error;
    }
  };
}

export default ApiService;
