import React, { useEffect, useState } from "react";
import {
  Chip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { MdOutlineLocationOn } from "react-icons/md";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useGlobalContext } from "context/global_context";
import ApiService from "../../services/api";

export default function GesCard () {
  const navigate = useNavigate();
  const { invDatas, getInvDatas } = useGlobalContext();

  useEffect(() => {
    getInvDatas();
  }, []);

  const [totals, setTotals] = useState({
    totalDailyEnergy: 0,
    totalEnergy: 0,
    totalActivePower: 0,
    totalInputPower: 0,
    averageEfficiency: 0,
  });


  useEffect(() => {
    if (invDatas && invDatas.length > 0) {
      const lastSixData = invDatas.slice(-6);
      console.log(lastSixData)
      const totalDailyEnergy = lastSixData.reduce(
        (total, data) => total + data.daily_energy,
        0
      );
      const totalEnergy = lastSixData.reduce(
        (total, data) => total + data.total_energy,
        0
      );

      const totalActivePower = lastSixData.reduce(
        (total, data) => total + data.active_power,
        0
      );
      
      const totalInputPower = lastSixData.reduce(
        (total, data) => total + data.input_power,
        0
      );
      const averageEfficiency =
        lastSixData.reduce((total, data) => total + data.efficiency, 0) /
        lastSixData.length;

      setTotals({
        totalDailyEnergy,
        totalEnergy,
        totalActivePower,
        totalInputPower,
        averageEfficiency,
      });
    }
  }, [invDatas]);

  return (
    <Button
      // onClick={() => navigate("/detail")}
      className="ml-6 mt-6 monitor:w-96 desktop:w-[22rem] blaptop:w-80 laptop:w-[22rem] slaptop:w-80 btablet:w-80 tablet:w-96 stablet:w-96 h-max bg-blue-950 text-white rounded-lg"
    >
      <Card className="monitor:w-96 desktop:w-[22rem] blaptop:w-80 laptop:w-[22rem] slaptop:w-80 btablet:w-80 tablet:w-96 stablet:w-96 h-max bg-blue-950 text-white normal-case rounded-lg">
        <CardBody className="flex ">
          <Typography variant="h5" color="blue-gray" className="mb-2 m-1">
            <p className="ml-1 font-semibold ">Akgün Elektrik</p>
            <p className="flex">
              <MdOutlineLocationOn />
              <p className="text-xs">Denizli</p>
            </p>
          </Typography>
          <Typography className="flex m-1">
            <p className="absolute right-2">Akgün GES</p>
            <p className="text-xs absolute right-2 top-7 ">
              Kurulu Güç: 272.000 kW
            </p>
          </Typography>
        </CardBody>
        <div className="flex flex-row">
          <Card className="mt-2 mx-2 h-20 text-white bg-gradient-to-b from-lime-500 to-lime-800 rounded-lg basis-1/2 text-center">
            <p className="text-sm font-semibold mt-2">Günlük Enerji</p>
            <p className="text-2xl font-semibold">
              {totals.totalDailyEnergy.toFixed(2)} kWh
            </p>
          </Card>
          <Card className="mr-2 mt-2 h-20 text-white bg-gradient-to-b from-lime-500 to-lime-800 rounded-lg basis-1/2 text-center">
            <p className="text-sm font-semibold mt-2">Toplam Enerji</p>
            <p className="text-2xl font-semibold">
              {(totals.totalEnergy / 1000).toFixed(2)} MWh
            </p>
          </Card>
        </div>
        <div className="flex flex-row">
          <Card className="mt-2 mx-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">Anlık Güç</p>
            <p className="text-lg font-semibold">
              {totals.totalActivePower.toFixed(1)} kW
            </p>
          </Card>
          <Card className="mr-2 mt-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">Giriş Gücü</p>
            <p className="text-lg font-semibold">
              {totals.totalInputPower.toFixed(2)} kW
            </p>
          </Card>
          <Card className="mr-2 mt-2 h-16 text-white bg-gradient-to-b from-sky-500 to-sky-800 rounded-lg basis-1/3 text-center">
            <p className="text-xs font-semibold mt-2">İnvertör Sayısı</p>
            <p className="text-xl font-semibold">6</p>
          </Card>
        </div>
        <div className="flex flex-row">
          <Card className="my-2 mx-2 h-16 text-white bg-gradient-to-b from-sky-800 to-sky-950 rounded-lg basis-1/2 pl-1 flex flex-row">
            <p className="text-lg font-semibold mt-4 basis-1/2">Verimlilik</p>
            <p className="text-lg font-semibold mt-4 basis-1/2">
              %{totals.averageEfficiency.toFixed(2)}
            </p>
          </Card>
          <Card className="mr-2 my-2 h-16 text-white bg-gradient-to-b from-sky-800 to-sky-950 rounded-lg basis-1/2 pl-1 flex flex-row">
            <p className="text-lg font-semibold mt-1 basis-1/2">
              Santral <br />
              Durumu
            </p>
            <div className="basis-1/2 pl-7">
              <Chip
                value="Aktif"
                className="animate-pulse rounded-full text-center text-sm mt-4 mr-2 normal-case bg-green-700"
              />
            </div>
          </Card>
        </div>
      </Card>
    </Button>
  );
};

