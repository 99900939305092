import React from "react";
import solarDataLogo from "../../assets/images/SolarDataLogo.png";

export default function NavBar() {
  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      <header className="h-24 flex bg-blue-950 w-full drop-shadow-xl">
        <img
          src={solarDataLogo}
          alt="Solar Data Tracker"
          className="h-20 w-auto m-2 ml-6"
        />
        {/* <p className="text-white text-xs italic">Yapay Öğrenme Destekli Analiz Sistemi</p> */}
      </header>
    </div>
  );
}
