import React, { useState } from "react";
import { FaBars, FaTimes, FaChevronRight, FaChevronDown } from "react-icons/fa";
import { CgList } from "react-icons/cg"
import akgunLogo from "../../assets/images/akgun_beyaz_lo.png";
import panelIcon from "../../assets/icons/solar-cell.png";
import analysisIcon from "../../assets/icons/graph.png"
import mlIcon from "../../assets/icons/machine-learning.png"
import addIcon from "../../assets/icons/more.png"
import listIcon from "../../assets/icons/list.png"
import settingsIcon from "../../assets/icons/settings.png"

import { Link } from "react-router-dom";
//Router eklenecek sidebar içeriğine karar verildiğinde

import { HomeIcon, UsersIcon, BellAlertIcon} from "@heroicons/react/24/outline";

export default function SideBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <div className="bg-blue-950 text-white text-lg h-screen monitor:w-64 w-60 py-6 px-2">
        <ul className="text-white font-semibold">
          <li className="bg-orange-700 rounded-lg p-3">
            <a href="/" className="flex">
              <HomeIcon className="h-7 w-7" />
              <span className="ml-2">Ana Sayfa</span>
            </a>
          </li>
          <li className="hover:bg-sky-300/10 rounded-lg p-3">
            <a href="#" className="flex">
              <img src={panelIcon} className="h-7 w-7" />
              <span className="ml-2">Santraller</span>
            </a>
          </li>
          <li className="hover:bg-sky-300/10 rounded-lg p-3">
            <a href="#" className="flex ">
              <BellAlertIcon className="h-7 w-7" />
              <span className="ml-2">Alarmlar</span>
            </a>
          </li>
          <li className="hover:bg-sky-300/10 rounded-lg p-3">
            <a href="#" className="flex">
              <img src={analysisIcon} className="h-7 w-7" />
              <span className="ml-2">Detay Bilgi</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="flex items-center hover:bg-sky-300/10 rounded-lg p-3"
              onClick={toggleDropdown}
            >
              <img src={settingsIcon} className="h-7 w-7" />
              <span className="ml-2">Ayarlar</span>
              {isDropdownOpen ? (
                <FaChevronDown className="ml-14" />
              ) : (
                <FaChevronRight className="ml-14" />
              )}
            </a>
            {isDropdownOpen && (
              <ul className="pl-4 m-2">
                <li className="hover:bg-sky-300/10 rounded-lg p-3 flex">
                  <img src={addIcon} className="h-7 w-7" />
                  <a href="#" className="ml-2">
                    Santral Ekle
                  </a>
                </li>
                <li className="hover:bg-sky-300/10 rounded-lg p-3 flex">
                  <UsersIcon className="h-7 w-7" />
                  <a href="#" className="ml-2">
                    Kullanıcılar
                  </a>
                </li>
                <li className="hover:bg-sky-300/10 rounded-lg p-3 flex">
                  <img src={listIcon} className="h-7 w-7" />
                  <a href="#" className="ml-2">
                    Firmalar
                  </a>
                </li>
              </ul>
            )}
          </li>
        </ul>
        {/* <div className="px-2 w-64 absolute inset-x-0 bottom-0 flex flex-col text-sm justify-center items-center my-3">
          <img src={akgunLogo} alt="Akgün Elektrik" className="w-40 mb-1" />
          <div className="text-gray-400 text-sm ">Tasarım Merkezi</div>
        </div> */}
      </div>
    </>
  );
}
