import MainPage from "components/mainpage/MainPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "components/404";
import GesDetail from "components/ges_details/GesDetails";
import { GlobalProvider } from "context/global_context";

function App() {
  return (
    <GlobalProvider> 
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/detail" element={<GesDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
