import React from "react";
import SideBar from "../navigation/SideBar";
import NavBar from "../navigation/NavBar";
import { Card, Typography } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import SocketService from "../../services/socket";

const table_head = ["Üretim", "Tüketim", "Anlık Güç", "Santral Durumu", "Tarih/Saat"]

export default function GesDetail() {
  return (
    <div>
      <NavBar />
      <div className="flex-none fixed top-24">
        <SideBar />
      </div>

      <div className="flex">
        <div className="ml-72 mt-28 mb-6">
          <Card className="relative flex flex-col w-full h-full text-white bg-blue-950 shadow-md rounded-xl bg-clip-border">
            <table className="w-full text-left table-auto min-w-max">
              <thead>
                <tr>
                  {table_head.map((head) => (
                    <th
                      key={head}
                      className="p-5 border-b border-gray-600 bg-blue-gray-50"
                    >
                      <Typography
                        variant="small"
                        className="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-4 border-b border-gray-600 bg-blue-gray-50">
                    <p className="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                      120 kW
                    </p>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
}

