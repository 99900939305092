import React, { useEffect, useState } from "react";
import SideBar from "../navigation/SideBar";
import NavBar from "../navigation/NavBar";
import GesCard from "./GesCard";
import GesCardPassive from "./GesCardPassive";
import GesCardPassive1 from "./GesCardPassive1";
import GesCardPassive2 from "./GesCardPassive2";
import GesCardPassive3 from "./GesCardPassive3";
import GesCardPassive4 from "./GesCardPassive4";
import GesCardPassive5 from "./GesCardPassive5";

import { useGlobalContext } from "../../context/global_context";

export default function MainPage() {
  const { getInvDatas } = useGlobalContext();

  return (
    <div className="z-50">
      <NavBar />
      <div className="flex-none fixed top-24">
        <SideBar />
      </div>

      <div className="flex">
        <div className="grid monitor:grid-cols-4 lmonitor:grid-cols-5 desktop:grid-cols-4 blaptop:grid-cols-4 laptop:grid-cols-3 slaptop:grid-cols-3 btablet:grid-cols-2 tablet:grid-cols-2 monitor:ml-64 ml-60 mt-24 mb-6">
          <GesCard />
          <GesCardPassive />
          <GesCardPassive1 />
          <GesCardPassive2 />
          <GesCardPassive3 />
          <GesCardPassive4 />
          <GesCardPassive5 />
          <GesCardPassive3 />
          <GesCardPassive />
        </div>
      </div>
    </div>
  );
}
